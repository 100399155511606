import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from 'next/router';
import Image from 'next/future/image';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import {
  Attached,
  BottomNavigation,
  CircleButton,
  Bookmark,
  SocialSchemaOrg,
} from 'bf-ui';

import styles from './main.layout.scss';

import HomeIcon from '/src/assets/images/icons/home-bottom.svg';
import MobileIcon from '/src/assets/images/icons/mobile-icon.svg';
// import LottoIcon from '/src/assets/images/icons/lotto.svg';
import InstantIcon from '/src/assets/images/icons/instant.svg';
import WalletIcon from '/src/assets/images/icons/wallet.svg';
import WheelIcon from '/src/assets/images/icons/wheel.png';
// import CommissionFilledIcon from '/src/assets/images/icons/commission-filled.svg';

import websiteConfig from '../../../configs/websiteConfig';
import { setDrawerOpen } from '../../../redux/global.slice';
import globalEmitter, { emits } from '../../../emitter/global.emitter';
import ModalsEnum from '../../../enums/modals.enum';
import { getItem } from '../../../helpers/cookie.helper';
import CookiesEnum from '../../../enums/cookies.enum';
import LinkWrapper from '../../wrappers/link/link.wrapper';
import { isClient } from '../../../helpers/detector.helper';

const MainHeader = dynamic(
  () => import('../../sections/headers/main/main.header'),
  {
    loading: () => <></>,
  }
);

const MainFooter = dynamic(
  () => import('../../sections/footers/main/main.footer'),
  {
    loading: () => <></>,
  }
);

const MainDrawer = dynamic(
  () => import('../../sections/drawers/main/main.drawer'),
  {
    loading: () => <></>,
  }
);

const CookieInfoSection = dynamic(
  () => import('../../sections/cookie-info/cookieInfo.section'),
  {
    loading: () => <></>,
  }
);
const wheelExcluded = ['/lucky-wheel', '/faq'];

const excludedPaths = [
  '/games/[gameKey]',
  '/login',
  '/sign-up',
  '/landing',
  '/free-spins',
  '/faq',
  '/verification',
  '/register',
  '/register-5free',
  '/register-25free',
];

const privatePaths = ['/deposit', '/withdraw', '/account', '/commission'];
const pathNoLoginPaths = ['/sign-up', '/register', '/register-5free'];

function MainLayout({ children }) {
  const router = useRouter();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [showCookiePolicy, setShowCookiePolicy] = useState(false);
  const drawerOpen = useSelector((state) => state.global.drawerOpen);

  const { asPath, query } = router;

  const sameAs = useMemo(() => {
    const arr = [];

    if (websiteConfig.facebook) {
      arr.push(websiteConfig.facebook);
    }

    if (websiteConfig.instagram) {
      arr.push(websiteConfig.instagram);
    }

    if (websiteConfig.linkedin) {
      arr.push(websiteConfig.linkedin);
    }

    if (websiteConfig.telegram) {
      arr.push(websiteConfig.telegram);
    }

    if (websiteConfig.pinterest) {
      arr.push(websiteConfig.pinterest);
    }

    if (websiteConfig.twitter) {
      arr.push(websiteConfig.twitter);
    }

    if (websiteConfig.youtube) {
      arr.push(websiteConfig.youtube);
    }

    if (websiteConfig.tiktok) {
      arr.push(websiteConfig.tiktok);
    }

    return arr;
  }, []);

  const isExcluded = excludedPaths.includes(router.route);

  const { id } = useSelector((state) => state.user);
  const { isMobile } = useSelector((state) => state.global);

  const onChatClick = useCallback(() => {
    if (window?.Tawk_API) {
      window.Tawk_API.toggle();
    }

    if (window?.FreshworksWidget) {
      window.fcWidget.open();
      window.fcWidget.show();
      // window.FreshworksWidget('open');
      // window.FreshworksWidget('show');
    }
  }, []);

  const onWheelClick = useCallback(() => {
    if (id) {
      globalEmitter.emit(emits.MODAL, {
        id: ModalsEnum.LUCKY_WHEEL,
        data: null,
      });
    } else {
      router.push('/login');
    }
  }, [id]);
  useEffect(() => {
    if (isClient() && !id && query.cmdaction === 'register') {
      globalEmitter.emit(emits.MODAL, {
        id: ModalsEnum.REGISTER,
      });
    }
  }, [query]);
  const checkForCookiePolicy = useCallback(() => {
    const shown = getItem(CookiesEnum.COOKIE_POLICY);

    if (!shown) {
      setShowCookiePolicy(true);
    }
  }, []);

  useEffect(() => {
    const pathextract = asPath.split('?');

    if (!id && privatePaths.includes(pathextract[0])) {
      router.push('/login', '/login');
    }
    if (id && pathNoLoginPaths.includes(pathextract[0])) {
      router.push('/', '/');
    }
    // console.log('asPath', asPath, pathextract[0]);
  }, [id, asPath]);

  useEffect(() => {
    if (drawerOpen) {
      dispatch(setDrawerOpen(false));
    }
  }, [router.pathname]);

  useEffect(() => {
    checkForCookiePolicy();
  }, []);

  return (
    <>
      <main role="document" tabIndex={0}>
        {!isExcluded && <MainHeader />}
        {children}
        {!isExcluded && (
          <>
            <MainFooter />
            {isMobile && (
              <div className="show_mobile">
                <BottomNavigation>
                  <LinkWrapper
                    href="/"
                    className={classnames(
                      'tab_link',
                      asPath === '/' ? 'active' : ''
                    )}
                  >
                    <Image src={HomeIcon} width={20} height={20} alt="home" />
                    {/*<span className="icon-home" />*/}
                    <span>{intl.messages.home}</span>
                  </LinkWrapper>
                  <LinkWrapper
                    href="/recent-winners"
                    className={classnames(
                      'tab_link',
                      'animated',
                      asPath === '/recent-winners' ? 'active' : ''
                    )}
                  >
                    <span className="icon-cup" />

                    <span>{intl.messages.winners}</span>
                  </LinkWrapper>
                  <LinkWrapper href="/deposit" privateRoute>
                    <CircleButton>
                      <Image
                        src={WalletIcon}
                        width={36}
                        height={36}
                        alt="home"
                      />
                    </CircleButton>
                  </LinkWrapper>
                  {websiteConfig.appLink ? (
                    <a
                      className="tab_link"
                      href={websiteConfig.appLink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Image
                        src={MobileIcon}
                        width={20}
                        height={20}
                        alt="App"
                      />
                      <span>{intl.messages.app}</span>
                    </a>
                  ) : (
                    <LinkWrapper
                      href="/instant-win"
                      className={classnames(
                        'tab_link',
                        asPath === '/instant-win' ? 'active' : ''
                      )}
                    >
                      <Image
                        src={InstantIcon}
                        width={20}
                        height={20}
                        alt="Instant"
                      />
                      <span>{intl.messages.fast}</span>
                    </LinkWrapper>
                  )}

                  <LinkWrapper
                    href="/promotions"
                    className={classnames(
                      'tab_link',
                      'promotions_bottom',
                      asPath === '/promotions' ? 'active' : ''
                    )}
                  >
                    <span className="icon-announcement" />
                    <span>{intl.messages.promotions}</span>
                  </LinkWrapper>
                </BottomNavigation>
              </div>
            )}
          </>
        )}
        {!isExcluded &&
          websiteConfig.wheelAvailable &&
          !wheelExcluded.includes(router.route) && (
            <Bookmark>
              <div className="bookmark_wheel_content" onClick={onWheelClick}>
                <Image src={WheelIcon} className="bookmark_wheel" width={45} />
                <span className="bookmark_wheel_text">
                  {intl.messages.wheel}
                </span>
              </div>
            </Bookmark>
          )}
        {(websiteConfig.tawkId || websiteConfig.fcWidgetId) && !isExcluded && (
          <Attached
            className="attached_chat"
            anchor="left"
            label={intl.messages.chat}
            onClick={onChatClick}
          />
        )}
        {drawerOpen && <MainDrawer />}
        {/*{showCookiePolicy && (*/}
        {/*  <CookieInfoSection onClose={() => setShowCookiePolicy(false)} />*/}
        {/*)}*/}
      </main>
      <SocialSchemaOrg
        name={websiteConfig.name}
        url={websiteConfig.url}
        sameAs={sameAs}
      />
      <style jsx>{styles}</style>
    </>
  );
}

export default MainLayout;
